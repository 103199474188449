import React, { useEffect, useState } from "react";
import HomeNav from "../Component/HomeNav";
import {
    Button,
    CircularProgress,
    Container,
    Grid,
    InputAdornment,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import cutdevice from "../Assets/Images/cui-devices 1.png";
import product from "../Assets/Images/Product 3.png";
import "./home.css";
import Chart from "react-apexcharts";
import { IoIosArrowDown, IoMdPricetag } from "react-icons/io";
import { RiStockFill } from "react-icons/ri";
import { IoSearch } from "react-icons/io5";
import ProductModal from "./ProductModal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { searchProduct } from "../Store/searchProductSlice";
import axios from "axios";
import Loader from "../Component/Loader";
import { addToCart, getCartItems } from "../Store/cartSlice";
import { baseUrl } from "../Constant_key";
import { RiLock2Line } from "react-icons/ri";
import { IoMdArrowDropdown } from "react-icons/io";
import LoginModal from "../Component/LoginModal";
import { FaUser } from "react-icons/fa6";
import Swal from "sweetalert2";
import images from "../Assets/Images/images.png";

const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        "& input": {
            fontSize: "0.875rem", // Adjust font size
            padding: "6px 8px", // Adjust padding to make the height smaller
            color: "black", // Ensure text is black in normal state
        },
    },
    "& .Mui-disabled": {
        color: "black !important", // Override default grey color for disabled state
        WebkitTextFillColor: "black !important", // Ensure WebKit browsers display black text
        cursor: "not-allowed",
        opacity: 1, // Remove default opacity for the entire disabled state
    },
    "& .MuiInputBase-input.Mui-disabled": {
        backgroundColor: "white", // Background color for disabled input
        color: "black !important", // Ensure disabled input text is black
        WebkitTextFillColor: "black !important", // Fix for WebKit browsers
    },
    "& .MuiInputAdornment-root.Mui-disabled": {
        color: "black !important", // Ensure adornment (e.g., $ symbol) is black when disabled
    },
}));

export default function ProductDetails() {
    const { partId } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const cartItems = useSelector(
        (state) => state?.cartReducer?.items?.items || []
    );
    const defaultPartImage = images;
    const quantity = location.state?.quantity;

    const [orderAllowStatus, setOrderAllowStatus] = useState(false);
    const [partPrice, setPartPrice] = useState(null);
    const [franchisePricing, setFranchisePricing] = useState("");
    const [disFranchisePricing, setDisFranchisePricing] = useState("");
    const [isPricingComming, setIsPricingComming] = useState(false);
    const [isWishLoader, SetIsWishLoader] = useState(false);
    const [editedQuantity, setEditedQuantity] = useState(quantity);
    const [editedFranchisePrice, setEditedFranchisePrice] =
        useState(disFranchisePricing);
    const [part, setPart] = useState(null);
    const [searchData, setSearchData] = useState(null);
    const [stockData, setStockData] = useState(null);
    const [pricingData, setPricingData] = useState(null);
    const [series, setSeries] = useState([
        {
            name: "Desktops",
            data: [280, 220, 320, 240, 230, 380, 250],
        },
    ]);
    const [pricingSeries, setPricingSeries] = useState([
        {
            name: "Desktops",
            data: [280, 220, 320, 240, 230, 380, 250],
        },
    ]);
    const [stockSeries, setStockSeries] = useState([
        {
            name: "Desktops",
            data: [280, 220, 320, 240, 230, 380, 250],
        },
    ]);
    const [searchOpt, setSearchOpt] = useState([]);
    const [priceOpt, setPriceOpt] = useState([]);
    const [stockOpt, setStockOpt] = useState([]);
    const [isLogin, setIsLogin] = useState(false);
    const [searchModalopen, setSearchModalOpen] = useState(false);
    const [loginModalopen, setLoginModalOpen] = useState(false);
    const [loadings, setLoading] = useState(false);
    const { product, loading, error } = useSelector((state) => state?.searchProduct);

    useEffect(() => {
        const res = localStorage.getItem("isLogin");

        if (res == "true") {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
    }, []);

    const isInCart =
        Array.isArray(cartItems) &&
        cartItems.some((item) => {
            return (
                item.part_number?.toLowerCase() ===
                product?.partDetails?.ManufacturerPartNumber?.toLowerCase()
            );
        });

    const getIpAddress = async () => {
        try {
            const response = await axios.get(
                "https://api.ipify.org?format=json"
            );
            return response.data.ip;
        } catch (error) {
            console.error("Error fetching IP:", error);
            return null;
        }
    };

    useEffect(() => {
        setLoading(true)
        getProductDetails();
        setEditedQuantity(quantity);
        getAnalytics();
    }, [partId]);

    const getProductDetails = async () => {
        if (partId !== "") {
            const ip = await getIpAddress();
            const token = await localStorage.getItem("token");
            const data = {
                partNumber: partId,
                ip: ip || "Unknown",
                token: token || "NA",
                appName: "RFQ-Genie"
            };
            dispatch(searchProduct(data));
        }
    };

    const getAnalytics = () => {
        setLoading(true)
        const value = {
            partNumber: partId,
            range: 7,
            quantity: parseInt(quantity),
            appName: "RFQ-Genie"
        };
        const token = localStorage.getItem("token");
        axios
            .post(`${baseUrl}api/part-analytics`, value, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                if (res.data.status == 200) {
                    setFranchisePricing(
                        res.data?.externalPricingData?.franchisePricing
                    );
                    setEditedFranchisePrice(
                        res.data?.externalPricingData
                            ?.discountedFranchisePricing
                    );
                    setDisFranchisePricing(
                        res.data?.externalPricingData
                            ?.discountedFranchisePricing
                    );
                    if (res.data?.externalPricingData?.franchisePricing !== null && 
    res.data?.externalPricingData?.franchisePricing !== undefined) {
    setIsPricingComming(true);
} else {
    setIsPricingComming(res.data?.externalPricingData?.status);
}


                    if (!res.data.searchHistory.message) {
                        setSearchData(res.data.searchHistory);
                        const counts = res.data.searchHistory?.map(
                            (item) => item.count
                        );
                        const dates = res.data.searchHistory?.map(
                            (item) => item.date
                        );

                        setSeries([
                            {
                                name: "Total Searches",
                                data: counts,
                            },
                        ]);
                        setSearchOpt(dates);
                    } else {
                        setSearchData(false);
                    }

                    if (!res.data.pricingData.message) {
                        setPricingData(res.data.pricingData);
                        const prices = res.data.pricingData?.map(
                            (item) => item.price
                        );
                        const dates = res.data.pricingData?.map(
                            (item) => item.date
                        );
                        setPricingSeries([
                            {
                                name: "Price",
                                data: prices,
                            },
                        ]);
                        setPriceOpt(dates);
                    } else {
                        setPricingData(false);
                    }
                    if (!res.data.availabilityData.message) {
                        setStockData(res.data.availabilityData);
                        const availabilities = res.data.availabilityData?.map(
                            (item) => item.availability
                        );
                        const dates = res.data.availabilityData?.map(
                            (item) => item.date
                        );
                        setStockSeries([
                            {
                                name: "Total Stock Available",
                                data: availabilities,
                            },
                        ]);
                        setStockOpt(dates);
                    } else {
                        setStockData(false);
                    }

                    setLoading(false)
                }
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                // console.log(err)
            });
    };

    const wrapperStyle = {
        position: "relative",
        display: "inline-block",
        width: "5rem",
    };

    const selectStyle = {
        // backgroundColor: "#1F80C0",
        paddingInline: "5px",
        paddingBlock: "4px",
        border: "none",
        fontSize: "12px",
        outline: "none",
        width: "100%",
        color: "white",
        appearance: "none",
        WebkitAppearance: "none",
        MozAppearance: "none",
        borderRadius: "4px",
        cursor: "pointer",
        textAlign: "center",
        fontWeight: "bold",
        paddingRight: "20px", // Add space for the icon
    };

    const iconStyle = {
        position: "absolute",
        right: "5px",
        top: "50%",
        transform: "translateY(-50%)",
        pointerEvents: "none", // Prevent the icon from blocking clicks on the select
        color: "white",
        fontSize: "20px",
    };

    const optionStyle = {
        backgroundColor: "white",
        color: "black",
        fontWeight: "bold",
    };

    useEffect(() => {
        if (product && !loadings && !error) {
            setPart(product?.partDetails);
        }
        if (error) {
            toast.error(error?.message || "Part Not Found");
        }
    }, [product, loadings, error]);

    const priceOptions = {
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false, // Add this line to disable the toolbar
            },
        },
        dataLabels: {
            enabled: false,
        },
        colors: ["#16ACE3"],
        markers: {
            size: 4, // Size of the dots
            colors: ["#16ACE3"], // Color of the dots (matching your line color)
            strokeColors: "#fff", // Border color of the dots
            strokeWidth: 2, // Border width of the dots
            hover: {
                size: 8, // Size of dots on hover
            },
        },
        stroke: {
            curve: "straight",
            width: 1.5,
        },
        title: {
            text: undefined,
            align: "left",
        },
        grid: {
            row: {
                colors: ["transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        xaxis: {
            type: "datetime",
            categories: priceOpt,
            labels: {
                formatter: function (value, timestamp, opts) {
                    return new Date(value).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                });
                },
            },
        },
    yaxis: {
        labels: {
            formatter: function (value) {
                return value.toFixed(2); // Display values with two decimal places
            },
        },
        min: Math.min(...pricingSeries[0].data) * 0.9, // Calculate minimum value for scaling
        forceNiceScale: true, // Ensure a nice scaling with rounded values
    },
    tooltip: {
        x: {
            formatter: function (value) {
                return new Date(value).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                });
            },
        },
        y: {
            formatter: function (value) {
                return `$${value.toFixed(2)}`; // Show exact price value with 2 decimal places
            },
        },
    },
    };


 const stockOptions = {
    chart: {
        height: 350,
        type: "line", // Change the type from "line" to "bar"
        zoom: {
            enabled: false,
        },
        toolbar: {
            show: false,
        },
    },
    dataLabels: {
        enabled: false,
    },
    colors: ["#1F80C0"], // Retain the original color for consistency
    xaxis: {
        type: "datetime",
        categories: stockOpt, // Dates for the x-axis
        labels: {
            formatter: function (value) {
                return new Date(value).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                });
            },
        },
    },
    yaxis: {
        labels: {
            formatter: function (value) {
                if (value >= 1000000) {
                    return (value / 1000000).toFixed(2) + "M";
                } else if (value >= 1000) {
                    return (value / 1000).toFixed(0) + "K";
                } else {
                    return value;
                }
            },
        },
        min: 0, // Start y-axis at 0
        forceNiceScale: true, // Ensure a nice scaling with readable values
    },
    grid: {
        row: {
            colors: ["transparent"], // Keeps the background of grid rows transparent
            opacity: 0.5,
        },
    },
    tooltip: {
        y: {
            formatter: function (value) {
                return value.toLocaleString(); // Show the actual value with commas
            },
        },
    },
};


    const searchOptions = {
    chart: {
        height: 350,
        type: "line",
        zoom: {
            enabled: false,
        },
        toolbar: {
            show: false,
        },
    },
    dataLabels: {
        enabled: false,
    },
    colors: ["#231F20"],
    stroke: {
        curve: "straight",
        width: 1.5,
    },
    markers: {
        size: 4,
        colors: ["#231F20"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
            size: 8,
        },
    },
    title: {
        text: undefined,
        align: "left",
    },
    grid: {
        row: {
            colors: ["transparent"],
            opacity: 0.5,
        },
    },
    xaxis: {
        type: "datetime",
        categories: searchOpt,
        labels: {
            formatter: function (value, timestamp, opts) {
                // Format the date to "Sep 2022" without the specific day
                return new Date(value).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                });
            },
        },
    },
    yaxis: {
        labels: {
            formatter: function (value) {
                return Math.round(value); // Ensure only integer values are displayed
            },
        },
        min: 0, // Optional: Start y-axis at 0
        forceNiceScale: true, // Ensure a nice scaling with integer values
    },
};

    const handleLoginModalClose = () => {
        setLoginModalOpen(false);
    };

    const handleAddToCartBtn = (editedFranchisePrice) => {
        const value = {
            part_number: partId,
            quantity: quantity,
            price: editedFranchisePrice || partPrice,
        };
        if (!isInCart) {
            dispatch(addToCart(value))
                .unwrap()
                .then((response) => {
                    if (response.cartId) {
                        // Use the cartId to get all items in the cart
                        dispatch(getCartItems(response.cartId));
                    }
                })
                .catch((error) => {
                    if (error.message === "User not authenticated") {
                        // User is not authenticated, open the modal
                        setLoginModalOpen(true);
                        // setOpenModal(true);
                    } else {
                        // Handle other errors
                        console.error("Error adding to cart:", error.message);
                    }
                });
        }
    };

    const CustomPriceTitle = () => (
        <div
            style={{
                display: "flex",
                marginTop: "0.8rem",
                marginInline: "1rem",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "4px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                }}
            >
                <IoMdPricetag size={18} style={{ color: "#16ACE3" }} />
                <span
                    style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        color: "#16ACE3",
                    }}
                >
                    Price Analysis
                </span>
            </div>

          
        </div>
    );

    const CustomStockTitle = () => (
        <div
            style={{
                display: "flex",
                marginTop: "0.8rem",
                marginInline: "1rem",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "4px",
            }}
        >
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <RiStockFill size={18} style={{ color: "#1F80C0" }} />
                <span
                    style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        color: "#1F80C0",
                    }}
                >
                    Stock Analysis
                </span>
            </div>

        </div>
    );

    const CustomSearchTitle = () => (
        <div
            style={{
                display: "flex",
                marginTop: "0.8rem",
                marginInline: "1rem",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "4px",
            }}
        >
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <IoSearch size={18} style={{ color: "#231F20" }} />
                <span
                    style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        color: "#231F20",
                    }}
                >
                    Search Analysis
                </span>
            </div>

        </div>
    );

    const handleAskToWish = () => {
        SetIsWishLoader(true);
        const value = {
            wishprice: parseFloat(editedFranchisePrice),
            quantity: parseInt(editedQuantity),
            partNumber: partId,
            franchisePricing: franchisePricing,
            discFranchisePricing: disFranchisePricing,
            manufactureName: part?.Manufacturer,
        };

        const token = localStorage.getItem("token");
        axios
            .post(`${baseUrl}api/price-negotiation`, value, {
                headers: { Authorization: `bearer ${token}` },
            })
            .then((res) => {
                if (res.data.orderallowstatus == true) {
                    setPartPrice(editedFranchisePrice);
                    Swal.fire({
                        icon: "success",
                        confirmButtonText: "ADD TO CART",
                        text: res.data.message,
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            
                            handleAddToCartBtn(editedFranchisePrice); // Replace with your actual function name
                        }
                    });
                    
                    setOrderAllowStatus(true);
                    SetIsWishLoader(false);
                    // setPartPrice(editedFranchisePrice);
                } else {
                    Swal.fire({
                        icon: "error",
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: "Search Another Part",
                        title: "Sorry",
                        text: res.data.message,
                        customClass: {
                            cancelButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.cancel) {
                            navigate("/");
                        }
                    });
                }
            })
            .catch((err) => {
                if (err.response.status == 403) {
                    navigate("/login");
                    toast.error(
                        "Your session has expired. Please log in again to continue."
                    );
                }
                if (err.response.status == 500) {
                    toast.error(err.response?.data?.message);
                }
            })
            .finally(() => {
                SetIsWishLoader(false);
            });
    };

    return (
        <>
            <Loader loading={loadings || loading} />
            <HomeNav />

            <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <Container> */}
                <div className="mainnavbar">
                    <div className="detailspageborder">
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div className="productdetailsheaderoptions">
                                <label className="pageHeaderLabel">
                                    Part Number
                                </label>
                                <Typography className="pageheadervalues">
                                    {part?.ManufacturerPartNumber}
                                </Typography>
                            </div>
                            <div className="productdetailsheaderoptions">
                                <label className="pageHeaderLabel">
                                    Manufacturer
                                </label>
                                <Typography className="pageheadervalues">
                                    {part?.Manufacturer}
                                </Typography>
                            </div>

                            <div className="productdetailsheaderoptions">
                                <label className="pageHeaderLabel">
                                    Average Lead Time
                                </label>
                                <Typography className="pageheadervalues">
                                    {part?.LeadTime}
                                </Typography>
                            </div>
                            <div className="productdetailsheaderoptions">
                                <label className="pageHeaderLabel">
                                    Requested Quantity
                                </label>
                                <Typography className="pageheadervalues">
                                    {quantity}
                                </Typography>
                            </div>
                        </div>

                        <hr style={{ marginTop: "10px" }} />

                        <div style={{ marginTop: "1.5rem" }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <div
                                        style={{
                                            position: "relative",
                                            border: "1px solid #16ACE3",
                                            display: "flex",
                                            justifyContent: "center",
                                            borderRadius: "8px",
                                            height: "300px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "220px",
                                                objectFit: "contain",
                                            }}
                                            src={
                                                part?.ImagePath ==
                                                    "Not available" ||
                                                part?.ImagePath == "NA"
                                                    ? defaultPartImage
                                                    : part?.ImagePath
                                            }
                                        />
                                        {/* <img
                                            style={{
                                                position: "absolute",
                                                bottom: "20px",
                                                right: "20px",
                                            }}
                                            src={cutdevice}
                                        /> */}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} sm={6} lg={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} lg={6}>
                                            <div>
                                                <label className="dialoagLabels">
                                                    Manufacturer Part Number
                                                </label>
                                                <p className="dialogValues">
                                                    {
                                                        part?.ManufacturerPartNumber
                                                    }
                                                </p>
                                            </div>
                                        </Grid>
                                        {/* <Grid item xs={6} sm={6} lg={6}>
                                            {orderAllowStatus == true && (
                                                <div>
                                                    <label className="dialoagLabels">
                                                        Pricing (USD)
                                                    </label>
                                                    <div className="detailpagepricing">
                                                        <div
                                                            style={{
                                                                width: "100px",
                                                            }}
                                                            className="dialogValues"
                                                        >
                                                            Quantity
                                                        </div>
                                                        <span
                                                            className="dialogValues"
                                                            style={{
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {quantity}
                                                        </span>
                                                    </div>
                                                    <div className="detailpagepricing">
                                                        <div
                                                            style={{
                                                                width: "100px",
                                                            }}
                                                            className="dialogValues"
                                                        >
                                                            Unit Price
                                                        </div>
                                                        <span
                                                            className="dialogValues"
                                                            style={{
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {partPrice}
                                                        </span>
                                                    </div>
                                                    <div className="detailpagepricing">
                                                        <div
                                                            style={{
                                                                width: "100px",
                                                            }}
                                                            className="dialogValues"
                                                        >
                                                            Ext, Price
                                                        </div>
                                                        <span
                                                            className="dialogValues"
                                                            style={{
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            $
                                                            {(
                                                                quantity *
                                                                partPrice
                                                            ).toFixed(2)}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </Grid> */}

                                        <Grid item xs={12} sm={12} lg={6}>
                                            <div>
                                                <label className="dialoagLabels">
                                                    Datasheet
                                                </label>
                                                <div>
                                                    <a
                                                        target="_blank"
                                                        href={
                                                            part?.DataSheetUrl ==
                                                                "NA" ||
                                                            part?.DataSheetUrl ==
                                                                ""
                                                                ? undefined
                                                                : part?.DataSheetUrl
                                                        }
                                                        style={{
                                                            color:
                                                                part?.DataSheetUrl ==
                                                                    "NA" ||
                                                                part?.DataSheetUrl ==
                                                                    ""
                                                                    ? "gray"
                                                                    : "#16ACE3", // Optional: change color if 'NA'
                                                            fontWeight: 500,
                                                            textDecoration:
                                                                "none",
                                                            cursor:
                                                                part?.DataSheetUrl ==
                                                                    "NA" ||
                                                                part?.DataSheetUrl ==
                                                                    ""
                                                                    ? "not-allowed"
                                                                    : "pointer", // Optional: change cursor if 'NA'
                                                        }}
                                                        className="dialogValues"
                                                    >
                                                        {part?.DataSheetUrl ==
                                                            "NA" ||
                                                        part?.DataSheetUrl == ""
                                                            ? `NA`
                                                            : `${part?.ManufacturerPartNumber} Datasheet (PDF)`}
                                                    </a>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <div>
                                        {isPricingComming && (
                                            <Grid container columnSpacing={1}>
                                                <Grid
                                                    item
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xs={12}
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: "10px",
                                                        }}
                                                    >
                                                        <label className="dialoagLabels">
                                                            Franchise Price/Pc
                                                        </label>
                                                        <CustomTextField
                                                            fullWidth
                                                            autoComplete="off"
                                                            value={
                                                                franchisePricing
                                                            }
                                                            disabled
                                                            sx={{
                                                                marginTop:
                                                                    "3px",
                                                            }}
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                            placeholder="Enter Franchise Pricing"
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xs={12}
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: "10px",
                                                        }}
                                                    >
                                                        <label className="dialoagLabels">
                                                            Discounted Franchise
                                                            Price/Pc
                                                        </label>
                                                        <CustomTextField
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                            disabled
                                                            sx={{
                                                                marginTop:
                                                                    "3px",
                                                            }}
                                                            fullWidth
                                                            value={
                                                                disFranchisePricing
                                                            }
                                                            autoComplete="off"
                                                            placeholder="Enter Discounted Franchise Pricing"
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xs={12}
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: "10px",
                                                        }}
                                                    >
                                                        <label className="dialoagLabels">
                                                            Requested Quantity
                                                        </label>
                                                        <CustomTextField
                                                            sx={{
                                                                marginTop:
                                                                    "3px",
                                                            }}
                                                            fullWidth
                                                            value={
                                                                editedQuantity
                                                            }
                                                            onChange={(e) =>
                                                                setEditedQuantity(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            autoComplete="off"
                                                            placeholder="Enter Quantity"
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xs={12}
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: "10px",
                                                        }}
                                                    >
                                                        <label className="dialoagLabels">
                                                            Your Wish Price/Pcs
                                                        </label>
                                                        <CustomTextField
                                                            type="number"
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                            sx={{
                                                                marginTop:
                                                                    "3px",
                                                            }}
                                                            fullWidth
                                                            onChange={(e) =>
                                                                setEditedFranchisePrice(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            value={
                                                                editedFranchisePrice
                                                            }
                                                            autoComplete="off"
                                                            placeholder="Enter Wish Price/Pcs"
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )}

                                        {/* <div
                                            style={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            <label className="dialoagLabels">
                                                ECAD Model
                                            </label>
                                            <br />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <span
                                                    style={{ fontSize: "11px" }}
                                                >
                                                    Build or Request
                                                </span>
                                                <span
                                                    style={{ fontSize: "11px" }}
                                                >
                                                    PCB Footprint or Symbol
                                                </span>
                                                <span
                                                    style={{
                                                        fontSize: "11px",
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    Downlad the free{" "}
                                                    <span
                                                        style={{
                                                            color: "#16ACE3",
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        Library Loader
                                                    </span>{" "}
                                                    to convert this file for
                                                    your ECAD Tool.
                                                </span>
                                                <span
                                                    style={{
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        color: "#16ACE3",
                                                    }}
                                                >
                                                    Learn more about ECAD Model.
                                                </span>
                                            </div>
                                        </div> */}

                                        <div
                                            style={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            <label className="dialoagLabels">
                                                Description
                                            </label>
                                            <p className="dialogValues">
                                                {part?.partDescription}
                                            </p>
                                        </div>

                                        <div>
                                            {isLogin ? (
                                                <>
                                                    {orderAllowStatus == true || isInCart == true ? (
                                                        <Button
                                                            fullWidth
                                                            className="hoverbutton addtocartbtn"
                                                            onClick={handleAddToCartBtn}
                                                            variant="outlined"
                                                            disabled={isInCart}
                                                        >
                                                            {!isInCart
                                                                ? "ADD TO CART"
                                                                : "ADDED TO CART"}
                                                        </Button>
                                                    ) : (
                                                        <>
                                                            {isPricingComming ==
                                                            true ? (
                                                                <Button
                                                                    fullWidth
                                                                    className="hoverbutton addtocartbtn"
                                                                    onClick={
                                                                        handleAskToWish
                                                                    }
                                                                    variant="outlined"
                                                                >
                                                                    {isWishLoader ? (
                                                                        <CircularProgress
                                                                            size={20}
                                                                        />
                                                                    ) : (
                                                                        "ASK YOUR WISH"
                                                                    )}
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    fullWidth
                                                                    className="hoverbutton addtocartbtn"
                                                                    variant="outlined"
                                                                >
                                                                    Please wait... Magic is working
                                                                </Button>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <Button
                                                    fullWidth
                                                    className="hoverbutton addtocartbtn"
                                                    onClick={() =>
                                                        setLoginModalOpen(true)
                                                    }
                                                    variant="outlined"
                                                >
                                                    Login
                                                </Button>
                                            )}

                                            {/* <Button
                                                fullWidth
                                                className="hoverbutton addtocartbtn"
                                                onClick={handleAddToCartBtn}
                                                style={{
                                                    backgroundColor:
                                                        isInCart && "#6d6e71",
                                                    color: isInCart && "white",
                                                }}
                                                variant="outlined"
                                                disabled={isInCart}
                                            >
                                                {!isInCart
                                                    ? "ADD TO CART"
                                                    : "ADDED TO CART"}
                                            </Button> */}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <hr style={{ marginTop: "1rem" }} />

                        <div className="detailspageheading">
                            <p>Comprehensive Part Data Analysis</p>
                        </div>
                        <Grid container spacing={1} sx={{ marginTop: "10px" }}>
                            <Grid item xs={12}>
                                <div style={{ border: "1px solid lightgrey" }}>
                                    <div
                                        style={{
                                            border: "1px solid lightgrey",
                                            position: "relative", // Allows absolute positioning of the overlay
                                        }}
                                    >
                                        <CustomPriceTitle />
                                        <Chart
                                            options={{
                                                ...priceOptions,
                                                colors:
                                                    pricingData !== false
                                                        ? priceOptions.colors
                                                        : ["#16ACE3"], // Set chart color based on pricingData
                                            }}
                                            series={
                                                isLogin == true
                                                    ? pricingSeries
                                                    : []
                                            }
                                            type="line"
                                            height={200}
                                        />
                                        {pricingData === false &&
                                            isLogin == true && (
                                                <>
                                                    <div className="graphbluebackground">
                                                        <p
                                                            style={{
                                                                color: "white",
                                                                zIndex: 3,
                                                            }}
                                                        >
                                                            Price analysis is not available for the specified part number
                                                        </p>
                                                    </div>
                                                    <div className="graphbluebackgroundlayer" />
                                                </>
                                            )}
                                        {isLogin == false && (
                                            <>
                                                <div className="graphbluebackground">
                                                    <RiLock2Line className="graphlockicon" />
                                                    <p
                                                        style={{
                                                            color: "white",
                                                            zIndex: 3,
                                                        }}
                                                    >
                                                        To Unlock{" "}
                                                        <span>
                                                            Part Data Analytics
                                                            Graph
                                                        </span>
                                                    </p>
                                                    <button
                                                        onClick={() =>
                                                            setLoginModalOpen(
                                                                true
                                                            )
                                                        }
                                                        className="graphLoginBtn"
                                                    >
                                                        Login
                                                    </button>
                                                </div>
                                                <div className="graphbluebackgroundlayer" />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div style={{ border: "1px solid lightgrey" }}>
                                    <div
                                        style={{
                                            border: "1px solid lightgrey",
                                        }}
                                    >
                                        <div
                                            style={{
                                                border: "1px solid lightgrey",
                                                position: "relative", // Allows absolute positioning of the overlay
                                            }}
                                        >
                                            <CustomSearchTitle />
                                            <Chart
                                                options={searchOptions}
                                                series={
                                                    isLogin == true
                                                        ? series
                                                        : []
                                                }
                                                type="line"
                                                height={200}
                                            />
                                            {searchData === false &&
                                                isLogin == true && (
                                                    <>
                                                        <div className="graphbluebackground">
                                                            <p
                                                                style={{
                                                                    color: "white",
                                                                    zIndex: 3,
                                                                }}
                                                            >
                                                                Search analysis is not available for the specified part number
                                                            </p>
                                                        </div>
                                                        <div className="graphbluebackgroundlayer" />
                                                    </>
                                                )}
                                            {isLogin == false && (
                                                <>
                                                    <div className="graphbluebackground">
                                                        <RiLock2Line className="graphlockicon" />
                                                        <p
                                                            style={{
                                                                color: "white",
                                                                zIndex: 3,
                                                            }}
                                                        >
                                                            To Unlock{" "}
                                                            <span>
                                                                Part Data
                                                                Analytics Graph
                                                            </span>
                                                        </p>
                                                        <button
                                                            onClick={() =>
                                                                setLoginModalOpen(
                                                                    true
                                                                )
                                                            }
                                                            className="graphLoginBtn"
                                                        >
                                                            Login
                                                        </button>
                                                    </div>
                                                    <div className="graphbluebackgroundlayer" />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div style={{ border: "1px solid lightgrey" }}>
                                    <div
                                        style={{
                                            border: "1px solid lightgrey",
                                            position: "relative", // Allows absolute positioning of the overlay
                                        }}
                                    >
                                        <CustomStockTitle />
                                        <Chart
                                            options={stockOptions}
                                            series={
                                                isLogin == true
                                                    ? stockSeries
                                                    : []
                                            }
                                            type="line"
                                            height={200}
                                        />
                                        {stockData === false &&
                                            isLogin == true && (
                                                <>
                                                    <div className="graphbluebackground">
                                                        <p
                                                            style={{
                                                                color: "white",
                                                                zIndex: 3,
                                                            }}
                                                        >
                                                            Stock analysis is not available for the specified part number
                                                        </p>
                                                    </div>
                                                    <div className="graphbluebackgroundlayer" />
                                                </>
                                            )}
                                        {isLogin == false && (
                                            <>
                                                <div className="graphbluebackground">
                                                    <RiLock2Line className="graphlockicon" />
                                                    <p
                                                        style={{
                                                            color: "white",
                                                            zIndex: 3,
                                                        }}
                                                    >
                                                        To Unlock{" "}
                                                        <span>
                                                            Part Data Analytics
                                                            Graph
                                                        </span>
                                                    </p>
                                                    <button
                                                        onClick={() =>
                                                            setLoginModalOpen(
                                                                true
                                                            )
                                                        }
                                                        className="graphLoginBtn"
                                                    >
                                                        Login
                                                    </button>
                                                </div>
                                                <div className="graphbluebackgroundlayer" />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                {/* </Container> */}
            </div>

            <LoginModal
                open={loginModalopen}
                handleClose={handleLoginModalClose}
            />
        </>
    );
}
