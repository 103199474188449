import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./home.css";
import HomeNav from "../Component/HomeNav";
import logo from "../Assets/Images/logo.png";
import element from "../Assets/Images/element.png";
import { MdCancel } from "react-icons/md";
import { IoSearchOutline } from "react-icons/io5";
import ProductModal from "./ProductModal";
import MyAccountModal from "./MyAccountModal";
import { searchProduct } from "../Store/searchProductSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../Component/Loader";
import axios from "axios";
import { BiSolidSearchAlt2 } from "react-icons/bi";

export default function Home() {
    const dispatch = useDispatch();
    const { product, loading, error } = useSelector(
        (state) => state?.searchProduct
    );
    const [open, setOpen] = useState(false);
    const [quantity, setQuantity] = useState(null);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [part, setPart] = useState(null);
    const [mfgPartNumber, setMfgPartNumber] = useState("");

    useEffect(() => {
        if (searchPerformed) {
            if (product && !loading && !error) {
                setOpen(true);
                setPart(product?.partDetails);
                setMfgPartNumber("");
                setSearchPerformed(false);  // Reset the search performed flag
            }
            if (error) {
                toast.error(error?.message || "Part Not Found");
                setMfgPartNumber("");
                setSearchPerformed(false);  // Reset the search performed flag
            }
        }
    }, [product, loading, error, searchPerformed]);

    const getIpAddress = async () => {
        try {
            const response = await axios.get(
                "https://api.ipify.org?format=json"
            );
            return response.data.ip;
        } catch (error) {
            console.error("Error fetching IP:", error);
            return null;
        }
    };

    const handleClickOpen = async () => {
        if (mfgPartNumber !== "") {
            setOpen(false);  // Close the modal before starting a new search
            const ip = await getIpAddress();
            const token = await localStorage.getItem("token");
            const data = {
                partNumber: mfgPartNumber,
                ip: ip || "Unknown",
                token: token || "NA",
                appName: "RFQ-Genie"
            };
            setSearchPerformed(true);
            dispatch(searchProduct(data));
        }
    };

    const handleClose = () => {
        setQuantity(0)
        setOpen(false);
    };

    return (
        <div>
            <Loader loading={loading} />
            <div className="homebg">
                <div>
                    <HomeNav handleSearchClickOpen={handleClickOpen} />
                </div>

                <div style={{ position: "relative" }}>
                    <img className="homeleftelement" src={element} />
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "60%",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <img className="homemainlogo" src={logo} />

                    <div style={{ display: "flex", zIndex: 999 }}>
                        <input
                            size="small"
                            placeholder="Search Manufacturer Part Number"
                            value={mfgPartNumber}
                            onChange={(e) => setMfgPartNumber(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleClickOpen();
                                }
                            }}
                            className="homemainpartsearchbox"
                        />
                        <button
                            onClick={handleClickOpen}
                            style={{
                                width: "4rem",
                                borderWidth: "1px",
                                cursor: 'pointer',
                                borderColor: "#16ACE3",
                                borderTopRightRadius: "6px",
                                borderBottomRightRadius: "6px",
                            }}
                        >
                            <BiSolidSearchAlt2
                                size={20}
                                style={{ fontWeight: "600" }}
                                color="#1F80C0"
                            />
                        </button>
                    </div>
                </div>

                <ProductModal
                    part={part}
                    open={open}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    handleClose={handleClose}
                />
            </div>
        </div>
    );
}
